'use client'

import UIButton from '@components/ui/UIButton'
import UIContainer from '@components/ui/UIContainer'
import UIFlex from '@components/ui/UIFlex'
import UIText from '@components/ui/UIText'
import { Box } from '@mui/joy'
import color from '@ui/style/color.theme'
import pxToRem from '@utils/pxToRem'
import muiTheme from '@ui/style/muiTheme'
import { notFound } from 'next/navigation'
import nProgress from 'nprogress'
import { Icon16ArrowMedium } from 'src/assets/icons/Icon16ArrowMedium'
import IconNotFound from 'src/assets/icons/IconNotFound'
import { APIResultCode } from 'src/shared/code'
import { MainTenance } from './(errors)/maintenance/MainTenance'
import IconNotFoundSm from 'src/assets/icons/IconNotFoundSm'
import * as Sentry from '@sentry/nextjs'
import { useEffect } from 'react'

export default function GlobalError({ error, reset }: { error: Error; reset: () => void }) {
	useEffect(() => {
		Sentry.captureException(error)
	}, [error])

	try {
		const errorObject = JSON.parse(error.message) as CommonResponse<unknown>
		console.log('(글로벌) errorObject', errorObject)

		if (
			Object.keys(errorObject).length === 0 ||
			errorObject.resultCode === APIResultCode.MAINTENANCE
		) {
			return <MainTenance resultMessage={errorObject.resultMessage} />
		}

		if (
			errorObject.resultCode === APIResultCode.INVALID_URL ||
			errorObject.resultCode === APIResultCode.INVALID_METHOD
		) {
			return notFound()
		}
	} catch (err) {
		console.log('Error occured in global-error.tsx', err)
	}

	const handleClickHome = () => {
		window.location.href = window.location.origin
	}

	return (
		<html lang="ko">
			<head />
			<body>
				<UIContainer>
					<UIFlex
						align="center"
						justify="center"
						sx={{
							height: '100dvh',
							paddingTop: pxToRem(184),
							paddingBottom: pxToRem(184),
							[muiTheme.breakpoints.down('md')]: {
								paddingTop: pxToRem(144),
								paddingBottom: pxToRem(144),
							},
						}}
					>
						<UIFlex
							align="center"
							justify="center"
							sx={{
								'&>*:not(:first-child)': {
									marginTop: pxToRem(12),
								},
							}}
						>
							<Box
								display={{
									xs: 'none',
									md: 'block',
								}}
								sx={{
									width: pxToRem(36),
									height: pxToRem(36),
								}}
							>
								<IconNotFound />
							</Box>
							<Box
								display={{
									xs: 'block',
									md: 'none',
								}}
								sx={{
									width: pxToRem(28),
									height: pxToRem(28),
								}}
							>
								<IconNotFoundSm />
							</Box>
							<UIText
								color={color.colGray5}
								sx={{
									fontWeight: 600,
									fontSize: {
										xs: pxToRem(20),
										md: pxToRem(22),
										lg: pxToRem(24),
									},
									lineHeight: {
										xs: pxToRem(30),
										md: pxToRem(32),
										lg: pxToRem(34),
									},
								}}
							>
								서비스 연결이 원활하지 않습니다.
							</UIText>
						</UIFlex>
						<UIFlex
							mt={{
								xs: pxToRem(24),
								lg: pxToRem(32),
							}}
							sx={{
								flexDirection: {
									xs: 'column',
									md: 'row',
								},
								'&>*:not(:first-child)': {
									marginLeft: {
										md: pxToRem(16),
									},
									marginTop: {
										xs: pxToRem(12),
										md: 0,
									},
								},
							}}
						>
							<UIButton
								variant="outlined"
								color="neutral"
								sx={{
									'&': {
										width: pxToRem(152),
										height: pxToRem(46),
										paddingTop: 0,
										paddingRight: pxToRem(17),
										paddingBottom: 0,
										paddingLeft: pxToRem(17),
										fontWeight: 600,
										fontSize: pxToRem(16),
										lineHeight: pxToRem(18),
										borderRadius: pxToRem(8),
										color: color.colBlack,
										borderColor: color.colGray3,
									},
									'&:active': {
										background: color.colGray1,
									},
								}}
								onClick={() => {
									nProgress.start()
									window.location.reload()
								}}
							>
								재시도
							</UIButton>
							<UIButton
								variant="outlined"
								color="neutral"
								sx={{
									'&': {
										width: pxToRem(152),
										height: pxToRem(46),
										paddingTop: 0,
										paddingRight: pxToRem(17),
										paddingBottom: 0,
										paddingLeft: pxToRem(17),
										fontWeight: 600,
										fontSize: pxToRem(16),
										lineHeight: pxToRem(18),
										borderRadius: pxToRem(8),
										color: color.colBlack,
										borderColor: color.colGray3,
									},
									'&:active': {
										background: color.colGray1,
									},
									svg: {
										marginLeft: `${pxToRem(4)}!important`,
									},
								}}
								onClick={handleClickHome}
							>
								<UIText whiteSpace="nowrap">JTBC 뉴스 홈</UIText>
								<Icon16ArrowMedium color={color.colBlack} />
							</UIButton>
						</UIFlex>
					</UIFlex>
				</UIContainer>
			</body>
		</html>
	)
}
